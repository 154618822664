import './App.css';
import {NavAndButtonBar} from './components/navigateAndBackButton/NavAndButtonBar/NavAndButtonBar';
import Introduction from './components/IntroductionPage/Introduction';
import {Routes, Route, useNavigate, useLocation} from 'react-router-dom';
import {Navigate} from 'react-router-dom';
import Login from './components/loginPage/Login';
import * as Constants from './Const';
import ProtectedRoutes from './routes/ProtectedRoutes';
import auth from './authentication/auth';
import QuestionsSetter from './components/Questions/QuestionsSetter/QuestionsSetter';
import WelcomePageGeneric from './components/WelcomePage/WelcomePageGeneric';
import SafetyInfoPage from './components/InfoPage/safetyInfoPage/safetyInfoPage';
import WorkoutDonePage from './components/InfoPage/workoutDonePage/workoutDonePage';
import {DoneForTodayPage} from './components/InfoPage/doneForTodayPage/doneForTodayPage';
import {Workout} from './components/WorkoutPage/Workout';
import AdminVideos from './components/AdminPage/AdminVideos';
import AdminUsers from './components/AdminPage/AdminUsers';
import {useEffect, useState} from 'react';
import {Box} from '@chakra-ui/react';
import ReactGA from 'react-ga4';
import {useApiClient} from './api';
import {useDispatch} from 'react-redux';
import {login} from './store/authSlice';
import AdminPage from './components/AdminPage/AdminPage';
import UserPage from './components/UserPage/UserPage';

ReactGA.initialize('G-8XCB8HHR2B');

function App() {
  const [dir, setDir] = useState<'rtl' | 'ltr'>('rtl');
  const [toggleChecked, setToggleChecked] = useState(false);
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const {postAuthNavigate} = useApiClient();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const idToken = localStorage.getItem('accessToken');
    if (idToken) {
      setIsLoggingIn(true);

      postAuthNavigate({idToken})
        .then((resData) => {
          const {user, firstTime} = resData;
          ReactGA.set({userId: user.id});

          dispatch(
            login({
              id: user.id,
              name: user.name,
              email: user.email,
              provider: 'google',
              firstTime: firstTime,
              role: user.role,
            })
          );

          if (location.pathname === '/' || location.pathname === '/login') {
            navigate('/introduction');
          }
        })
        .catch(() => {
          navigate('/login');
        })
        .finally(() => {
          setIsLoggingIn(false);
        });
    }
  }, []);

  const onChangeDir = (e: any) => {
    setToggleChecked(e.target.checked);
    dir === 'ltr' ? setDir('rtl') : setDir('ltr');
  };

  return (
    <Routes>
      <Route element={<ProtectedRoutes auth={auth} />}>
        <Route
          path={Constants.home}
          element={
            <>
              <Navigate to={Constants.loginPage} />
            </>
          }
        />
        <Route
          path={Constants.loginPage}
          element={
            <>
              <Box
                minHeight={'100vh'}
                backgroundColor={'#e5e5e5'}
                width={'100%'}
              >
                <NavAndButtonBar
                  direction={dir}
                  includeButton={false}
                  onChangeToggle={onChangeDir}
                  checked={toggleChecked}
                  includeLogoutButton={false}
                  includeJimmiButton
                  pageType={'beforeWorkOut'}
                  
                />
                <Login direction={dir} isLoggingIn={isLoggingIn} />
              </Box>
            </>
          }
        />

        <Route
          path={Constants.introPage}
          element={
            <>
              <NavAndButtonBar
                direction={dir}
                navBarLink={Constants.introPage}
                includeButton={true}
                onChangeToggle={onChangeDir}
                checked={toggleChecked}
                includeLogoutButton={true}
                includeJimmiButton
                pageType={'beforeWorkOut'}
              />
              <Introduction direction={dir} />
            </>
          }
        />
        <Route
          path={Constants.UserPage}
          element={
            <>
              <NavAndButtonBar
                direction={dir}
                navBarLink={Constants.introPage}
                includeButton={true}
                onChangeToggle={onChangeDir}
                checked={toggleChecked}
                includeLogoutButton={true}
                includeJimmiButton
                pageType={'beforeWorkOut'}
              />
            <UserPage/>
            </>
          }
        />

        <Route
          path={Constants.Questions}
          element={
            <>
              <NavAndButtonBar
                direction={dir}
                backPageLink={Constants.introPage}
                navBarLink={Constants.introPage}
                includeButton={true}
                onChangeToggle={onChangeDir}
                checked={toggleChecked}
                includeLogoutButton={true}
                includeJimmiButton
                pageType={'beforeWorkOut'}
                
              />
              <QuestionsSetter direction={dir} />
            </>
          }
        />


        <Route
        path={Constants.AdminPage}
        element={
          <>
            <NavAndButtonBar
              direction={dir}
              navBarLink={Constants.introPage}
              includeButton={true}
              onChangeToggle={onChangeDir}
              checked={toggleChecked}
              includeJimmiButton
              includeLogoutButton={true}
              pageType={'AdminPage'}
              
            />
            <AdminPage/>
          </>
        }
        >

            <Route
            path={Constants.UsersPage}
            element={
              <>
                <NavAndButtonBar
                  onChangeToggle={onChangeDir}
                  checked={toggleChecked}
                  includeJimmiButton={false}
                  pageType={'AdminUsers'}
                />
                <AdminUsers/>
              </>
            }
            />

            <Route
            path={Constants.VideosPage}
            element={
              <>
                <NavAndButtonBar
                  onChangeToggle={onChangeDir}
                  includeJimmiButton={false}
                  checked={toggleChecked}
                  pageType={'AdminVideos'}
                />
                <AdminVideos/>
              </>
            }
            />

        </Route>

        <Route
          path={Constants.Workout}
          element={
            <>
              <NavAndButtonBar
                direction={dir}
                backPageLink={Constants.SafetyInfoPage}
                navBarLink={Constants.WelcomePage}
                includeButton={true}
                onChangeToggle={onChangeDir}
                checked={toggleChecked}
                includeLogoutButton={true}
                includeJimmiButton
                
              />
              <Workout/>{' '}
            </>
          }
        />
      </Route>
      <Route
        path={Constants.WelcomePage}
        element={
          <>
            <NavAndButtonBar
              direction={dir}
              backPageLink={Constants.introPage}
              navBarLink={Constants.WelcomePage}
              includeButton={true}
              onChangeToggle={onChangeDir}
              checked={toggleChecked}
              includeLogoutButton={true}
              includeJimmiButton
            />
            <>
              <WelcomePageGeneric
                direction={dir}
              />
            </>
          </>
        }
      />
      <Route
        path={Constants.SafetyInfoPage}
        element={
          <>
            <NavAndButtonBar
              direction={dir}
              backPageLink={Constants.WelcomePage}
              navBarLink={Constants.WelcomePage}
              includeButton={true}
              onChangeToggle={onChangeDir}
              checked={toggleChecked}
              includeLogoutButton={true}
              includeJimmiButton
              
            />
            <SafetyInfoPage direction={dir} />
          </>
        }
      />
      <Route
        path={Constants.WorkoutDone}
        element={
          <>
            <NavAndButtonBar
              direction={dir}
              backPageLink={Constants.WelcomePage}
              navBarLink={Constants.WelcomePage}
              includeButton={true}
              onChangeToggle={onChangeDir}
              checked={toggleChecked}
              includeLogoutButton={true}
              includeJimmiButton
             
            />
            <WorkoutDonePage direction={dir} />
          </>
        }
      />
      <Route
        path={Constants.DoneForTodayPage}
        element={
          <>
            <NavAndButtonBar
              direction={dir}
              backPageLink={Constants.WelcomePage}
              navBarLink={Constants.WelcomePage}
              includeButton={true}
              onChangeToggle={onChangeDir}
              checked={toggleChecked}
              includeLogoutButton={true}
              includeJimmiButton             
              
            />
            <DoneForTodayPage direction={dir} />
          </>
        }
      />
      <Route path="*" element={<div>404 NOT FOUND</div>} />
    </Routes>
  );
}

export default App;
