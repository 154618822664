import React from 'react';
import {Button, Box} from '@chakra-ui/react';
import {useNavigate} from 'react-router-dom';
import * as Constants from '../../navigateAndBackButton/Const';
import '../../loginPage/LogoutStyle.css'

export const AdminButton = (props: {
    lang: 'rtl' | 'ltr';
}) => {
  const text = props.lang === 'rtl' ? 'דף מנהל' : 'admin page';
  const navigate = useNavigate();
  const gotoPage = () => {
    navigate(Constants.AdminPage);
  };

  return (
    <Box >
      <Button
      backgroundColor={'#7b6ad5'}
      cursor={'pointer'}
      color={'white'}
      size={'lg'}
      onClick={gotoPage}
      >{text}</Button>
    </Box>
  );
};
