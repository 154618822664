import React from "react";
import "./workoutDonePage";
import { useState } from "react";
import { AudioPlayer } from "./AudioPlayer/AudioPlayer";
import firstAudio from "../audio/doneForToday.m4a";
export const WorkoutDonePageHelper = (props) => {
  return props.direction === "rtl" ? ( 
    <div className="workoutDonePage">
      <div class="horizantalAlighn">
      <div div="lines">
      <div class="firstLineContainer">
      <AudioPlayer audioLink={firstAudio} direction="rtl" />
      <h5 className="firstLineWorkoutLeft">{props.firstInfoLine}</h5>
      </div>
        <h5 className="secondLineWorkoutLeft">{props.secondInfoLine}</h5>
        <h5 className="thirdLineWorkoutLeft">{props.thirdInfoLine}</h5>
        <h5 className="subtitleWorkoutLeft">{props.subtitleLine}</h5>
      </div>

      </div>
      <img src={props.Image} alt="" className="requiredJimiImageWorkout" />
      <button
        className="workoutDoneConbutton"
        onClick={() => props.continue(props.nextPage)}
      >
        {props.buttonLine}
      </button>
    </div>) : (
    <div className="workoutDonePage">
      <div class="horizantalAlighn">
      <div div="lines">
      <div class="firstLineContainer">
      <AudioPlayer audioLink={firstAudio} direction="ltr" />
      <h5 className="firstLineWorkoutLeft">{props.firstInfoLine}</h5>
      </div>
        <h5 className="secondLineWorkoutLeft">{props.secondInfoLine}</h5>
        <h5 className="thirdLineWorkoutLeft">{props.thirdInfoLine}</h5>
        <h5 className="subtitleWorkoutLeft">{props.subtitleLine}</h5>
      </div>
      </div>
      <img src={props.Image} alt="" className="requiredJimiImageWorkout" />
      <button
        className="workoutDoneConbutton"
        onClick={() => props.continue(props.nextPage)}
      >
        {props.buttonLine}
      </button>
    </div>
  );
};
