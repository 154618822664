import React from 'react';
import {AspectRatio} from '@chakra-ui/react';
import {useIsMobile} from '../../../hooks/useIsMobile';

const YoutubeVideoPlayer = ({videoId}) => {
  const isMobile = useIsMobile();
  return (
    <iframe
      width={isMobile ? '100%' : '800'}
      height={isMobile ? '200' : '450'}
      src={`https://www.youtube.com/embed/${videoId}?playlist=${videoId}&loop=1`}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded youtube"
    />
  );
};
export default YoutubeVideoPlayer;
