import Jimi from './images/littleJimi.png';
import logo from './images/special-jimi-logo-name.png';
import './WelcomePage.css';
import {Link, Navigate} from 'react-router-dom';
import {useLocation, useNavigate} from 'react-router-dom';
import * as Constants from '../../Const';
import constants from '../loginPage/constants';

const WelcomePage = (props) => {
  const navigate = useNavigate();
  const handleClick = (place) => {
    navigate(place);
  };
  return (
    <div dir={props.dir} className="welcomePage">
      <div className="horizantalAlignWelcomePage columnFlex">
        <img
          alt="Jimi"
          src={Jimi}
          className="width300 horizantalAlign clickable"
        />
        <h1 className="horizantalAlign welcomePagedefaultText" dir="rtl">
          {props.welcomeMessage + props.name}
        </h1>
        <h2 className="horizantalAlignWelcomePage defaultText" dir="rtl">
          {props.askWhatDoYouWant}
        </h2>
        <div className="rowDirection horizantalAlign">
          {props.listOfThings.map((e, i) => (
            <div
              key={`option-${i}`}
              className="circle clickable"
              onClick={() => handleClick(e.href)}
            >
              <div className="horizantalAlignWelcomePage columnFlex">
                <p className="wordInCircle">{e.val}</p>
                {e.picture ? <img src={e.picture} /> : null}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default WelcomePage;
