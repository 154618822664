import { createSlice } from "@reduxjs/toolkit";

export const authSlice = createSlice(
  {
  name: "userInfo",
  initialState: {
    value: { id: "", name: "", email: "", provider: "", firstTime: false, role: "" },
  },
  reducers: {
    login: (state, action) => {

      state.value = action.payload;
    },
    logout: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { login, logout } = authSlice.actions;
export default authSlice.reducer;
