import React, { useState, useEffect } from 'react';
import {
  Box,
  Link,
  Input,
  Button,
  VStack,
  Center,
  HStack,
  Text,
  Checkbox,
  Heading,
  useToast,
} from '@chakra-ui/react';
import {useApiClient} from '../../api';
import { Outlet, useNavigate} from 'react-router-dom';
import APPLICATION_CONSTANTS from '../../strings'
import * as Constants from '../../Const';

const Admin =  () => {
  const api = useApiClient();
  const toast = useToast();
  const navigate = useNavigate();

  const onUsersClick = () => {
    navigate(Constants.UsersPage);
  };

  const onVideosClick = () => {
    navigate(Constants.VideosPage);
  };

  return (
    <Box p={5}>

      <Center>
      <Heading mb={5}>Admin Page</Heading>
      </Center>


<Center>

  <Box p={5}>
  <Button

className="UsersButton"
backgroundColor={'#7b6ad5'}
cursor={'pointer'}
color={'white'}
size={'lg'}
onClick={onUsersClick}
_hover={{bg: '#7b6ad5', color: 'white'}}
>{APPLICATION_CONSTANTS.viewUsersButton}</Button>
  </Box>


<Button

className="VideosButton"
backgroundColor={'#7b6ad5'}
cursor={'pointer'}
color={'white'}
size={'lg'}
onClick={onVideosClick}
_hover={{bg: '#7b6ad5', color: 'white'}}
>{APPLICATION_CONSTANTS.viewVideosButton}</Button>
  
</Center>
<Outlet />
    </Box>
  );


};
export default Admin;






