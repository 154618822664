import React, {useState} from 'react';
const nextEnglish = require('../images/nextQuestionEnglish.png');
const nextMarked = require('../images/nextQuestion.png');
const Jimi = require('../images/Jimi3.png');
const stomach = require('./images/stomach.png');
const back = require('./images/back.png');
const head = require('./images/head.png');
const hand = require('./images/hand.png');
const leg = require('./images/leg.png');
const fine = require('./images/fine.png');

import {
  Box,
  Center,
  Flex,
  Heading,
  Image,
  ImageProps,
  SimpleGrid,
  Text,
} from '@chakra-ui/react';
import {useIsMobile} from '../../../hooks/useIsMobile';

const getImageSource = (option: string) => {
  if (option === 'stomach') {
    return stomach;
  }
  if (option === 'back') {
    return back;
  }
  if (option === 'head') {
    return head;
  }
  if (option === 'hand') {
    return hand;
  }
  if (option === 'leg') {
    return leg;
  }
  if (option === 'fine') {
    return fine;
  }
};

const checkedImageStyle: ImageProps = {
  width: '60%',
  height: '90%',
  background: '#ffffff',
  boxSizing: 'border-box',
  border: '4px solid #7B6AD5',
  boxShadow: '0px 1.87431px 5.62292px rgba(0, 0, 0, 0.15)',
  borderRadius: '15.9316px',
};

const uncheckedImageStyle: ImageProps = {
  width: '60%',
  height: '90%',
  background: '#ffffff',
  boxSizing: 'border-box',
  border: '0.937153px solid #e5e1f7',
  boxShadow: '0px 1.87431px 5.62292px rgba(0, 0, 0, 0.15)',
  borderRadius: '15.9316px',
};

export const MultiPictureSelectQuestionItem = (props) => {
  const [answers, setAnswers] = useState(new Array(props.info.options.length));
  const [next, setNext] = useState(false);
  const isMobile = useIsMobile();

  return (
    <Box key={props.info.id}>
      {props.firstTime && !isMobile ? (
        <Image src={props.info.progressBar} alt="top" />
      ) : null}
      <Flex
        dir={props.direction}
        alignItems={'center'}
        justifyContent={'center'}
      >
        {props.info.title !== '' ? (
          <Image
            src={Jimi}
            alt="Jimi"
            onClick={props.handleAudio}
            width={'59.08px'}
            height={'49.59px'}
            cursor={'pointer'}
            filter={'drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.15))'}
          />
        ) : null}
        <Heading
          as="h5"
          fontSize={'30px'}
          color={'#7b6ad5'}
          fontFamily={'Open Sans,sans-serif'}
        >
          {props.info.title}
        </Heading>
      </Flex>
      <Text
        marginTop={2}
        fontFamily={'Open Sans,sans-serif'}
        fontStyle={'normal'}
        fontWeight={'bold'}
        fontSize={'20px'}
        lineHeight={'23px'}
        color={'#313636'}
        dir={props.direction}
      >
        {props.info.subtitle}
      </Text>
      <Flex
        dir={props.direction}
        alignItems={'center'}
        justifyContent={'center'}
        marginTop={3}
      >
        {props.info.title === '' ? (
          <Image src={Jimi} alt="Jimi" onClick={props.handleAudio} />
        ) : null}
        <Heading
          as="h3"
          fontFamily={'Open Sans,sans-serif'}
          fontSize={'25px'}
          color={'#7b6ad5'}
        >
          {props.info.question}
        </Heading>
      </Flex>
      <Center marginTop={10}>
        ``
        <SimpleGrid columns={isMobile ? 2 : 3} spacing={10}>
          {props.info.options.map((option: string, index: number) => (
            <Center key={index.toString()}>
              <Image
                key={index}
                alt={option}
                src={getImageSource(option)}
                onClick={() =>
                  props.handleMultiSelect(
                    props.info.id,
                    props.info.optionsDescription[index],
                    index,
                    answers,
                    setAnswers,
                    setNext
                  )
                }
                {...(answers[index] ? checkedImageStyle : uncheckedImageStyle)}
              />
            </Center>
          ))}
        </SimpleGrid>
      </Center>
      <Center marginTop={10}>
        <Image
          src={props.direction === 'rtl' ? nextMarked : nextEnglish}
          alt="next-question"
          onClick={() => props.handleNext(next, setNext, setAnswers, [])}
          cursor={next ? 'pointer' : undefined}
          opacity={next ? 1 : 0.5}
          width={'329px'}
          height={'63px'}
          filter={'drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.15))'}
        />
      </Center>
    </Box>
  );
};
