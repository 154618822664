import React from 'react';
import {GoogleLogout} from 'react-google-login';
import './LogoutStyle.css';
import {useSelector, useDispatch} from 'react-redux';
import {logout} from '../../../store/authSlice';
import {resetWorkoutState} from '../../../store/workoutSlice';
resetWorkoutState
import {useNavigate} from 'react-router-dom';

const clientId =
  '996629174564-2hh9qacgckrfcsefdnqrbkj3q1j0nsks.apps.googleusercontent.com';
const LogoutBtn = (props) => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate('../login');
  };
  const user = useSelector((state) => state.user.value);
  const dispatch = useDispatch();
  const buttonClassName =
    props.direction === 'rtl' ? 'logoutBtnNavBar' : 'logoutBtnNavBarLeft';
  const handleFBLogout = () => {
    handleClick();
  };

  const onSuccess = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    dispatch(
      logout({id: '', name: '', email: '', provider: '', firstTime: false})
    );
    dispatch(resetWorkoutState());
    handleClick();
    console.log('Logout Successful!');
  };

  let content;


  content = (
    <GoogleLogout
      clientId={clientId}
      buttonText="Logout"
      onLogoutSuccess={onSuccess}
      className={buttonClassName}
      icon={false}
    />
  );


  return content;
};

export default LogoutBtn;
