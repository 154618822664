import React, { useState, useEffect } from 'react';
import {
  Box,
  Link,
  Input,
  Button,
  VStack,
  Center,
  HStack,
  Text,
  Checkbox,
  Heading,
  useToast,
} from '@chakra-ui/react';
import {useApiClient} from '../../api';
import { Outlet, useNavigate} from 'react-router-dom';
import APPLICATION_CONSTANTS from '../../strings'
import * as Constants from '../../Const';
import { useLocation } from 'react-router-dom';

const UserPage =  () => {
  const api = useApiClient();
  const navigate = useNavigate();
  const [logs, setlogs] = useState<Object>([]);
  const [logsAmount, setlogsAmount] = useState(0);
  const [firstVideoCount, setfirstVideoCount] = useState(0);
  const location = useLocation();
  const userId = location.state?.userId;

  useEffect(() => {
    const loadLogs = async () => {
      try {
        const allLogs = await api.getLogs(userId);
        setlogs(allLogs);
        setlogsAmount(allLogs.length);
      } catch (err) {
        console.error(err);
      } finally {
      }
    };
    loadLogs();
  }, []);

  function formatDate(dateString: Date) {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      timeZoneName: 'short'
    }).format(date);
  }

  return (
    <Box p={5}>
      <Center>
      <Heading className='main-header' mb={5}>User Logs</Heading>
      </Center>

      <Text>Total logs: {logsAmount}</Text>

      
      <VStack align="stretch" spacing={4}>
     
      {logs.map(log => (
          <Box key={log.id} p={4} borderWidth={1} borderRadius="md">
            <Text fontWeight="bold">{log.name}</Text>
            <Text>Date: {formatDate(log.createdAt)}</Text>
            <Text>Video Amount: {log.videoAmount}</Text>
            <Text>Hands Difficulty: {log.handsDifficulty}</Text>

            <Text>Times Fallen: {log.timesFallen}</Text>
            <Text>Assistance Type: {log.assistanceType}</Text>

            <Text>Hurting Body Parts: {log.hurtingBodyParts}</Text>
          </Box>
        ))}
      </VStack>
    </Box>
  );

};
export default UserPage;






