import {useEffect} from 'react';
import './LoginStyle.css';
import GoogleLoginBtn from './GoogleLogin';
import {gapi} from 'gapi-script';
const logoHebrew = require('./img/special-jimi-logo-name.png');
const logoEnglish = require('./img/spacial-jim-eng-logo.png');
const bigJim = require('./img/JimiSecondPrize.png');
import {Box, Center, Image, Flex, Text, Container} from '@chakra-ui/react';
import {useIsMobile} from '../../hooks/useIsMobile';
import {useNavigate} from 'react-router-dom';

const clientId =
  '996629174564-2hh9qacgckrfcsefdnqrbkj3q1j0nsks.apps.googleusercontent.com';

const Login = (props: {direction: string; isLoggingIn: boolean}) => {
  const navigate = useNavigate();

  useEffect(() => {
    function start() {
      gapi.client
        .init({
          clientId: clientId,
          scope: '',
        })
        .then(() => {
          const googleToken = gapi.client.getToken();
          if (googleToken !== null) {
            navigate('/introduction');
          }
        });
    }
    gapi.load('client:auth2', start);
  }, []);

  const logo = props.direction === 'rtl' ? logoHebrew : logoEnglish;
  const logoId = props.direction === 'rtl' ? 'rightLogo' : 'leftLogo';
  const text = props.direction === 'rtl' ? 'התחברות / הרשמה' : 'Sign up';
  const isMobile = useIsMobile();

  return (
    <Center
      className="login-container"
      flexDirection={'column'}
      backgroundColor={'#e5e5e5'}
    >
      <Flex marginTop={10}>
        <Container alignContent={'center'}>
          <Center>
            <Image src={logo} id={logoId} className="login_logo"></Image>
          </Center>
          <Center>
            {isMobile && (
              <Box boxSize={200} marginTop={10}>
                <Image src={bigJim} />
              </Box>
            )}
          </Center>
          <Text marginTop={30} fontSize={30} textAlign={'center'}>
            {text}
          </Text>
          <Center marginTop={10}>
            <GoogleLoginBtn isLoggingIn={props.isLoggingIn} />
          </Center>
        </Container>
        {!isMobile && (
          <Box boxSize={500}>
            <Image src={bigJim} />
          </Box>
        )}
      </Flex>
    </Center>
  );
};

export default Login;
