import React from 'react';
import {Text, Image, TextProps, Button} from '@chakra-ui/react';
import {useNavigate} from 'react-router-dom';
const ArrowBackImage = require('../images/Arrow_go_back.png');

export const BackButton = (props: {
  direction: 'rtl' | 'ltr';
  backPageLink: string;
}) => {
  const ArrowLine = props.direction === 'rtl' ? 'חזור' : 'Back';

  const navigate = useNavigate();

  const labelStyle: TextProps = {
    fontFamily: 'Arimo',
    fontStyle: 'normal',
    fontWeight: 'bold',
    color: '#313636',
    textAlign: 'center',
    fontSize: '20px',
  };

  const isRtl = props.direction === 'rtl';

  return (
    <Button
      onClick={() => navigate(props.backPageLink)}
      colorScheme={'white'}
      rightIcon={isRtl ? <Image src={ArrowBackImage} alt="" /> : undefined}
      padding={0}
      marginRight={3}
      marginLeft={3}
      leftIcon={
        isRtl ? undefined : (
          <Image
            src={ArrowBackImage}
            alt=""
            transform={isRtl ? undefined : 'rotate(180deg)'}
          />
        )
      }
    />
  );
};
