import React from 'react';
import './safetyInfoPage.css';
import {useNavigate} from 'react-router-dom';
import {SafetyInfoPageHelper} from './SafetyPageHelper';
import {Box} from '@chakra-ui/react';
const SafetyInfoPage = (props) => {
  const navigate = useNavigate();
  return props.direction === 'rtl' ? (
    <Box className="safetyPage">
      <Box className="audioPlayerSafetyPage"></Box>
      <SafetyInfoPageHelper
        firstInfoLine="שנייה לפני שמתחילים"
        secondInfoLine="אנא בידקו שאין"
        thirdInfoLine=" כיסא/שולחן/חפץ"
        forthInfoLine="שיפריע לכם בזמן האימון"
        subtitleLine="בסוף האימון"
        secondsubtitleLine="  !תקבלו פרס נוסף "
        thirdsubtitleLine={null}
        direction="rtl"
        buttonLine="שנתחיל?"
      />{' '}
    </Box>
  ) : (
    <Box className="safetyPage">
      <Box className="audioPlayerSafetyPageLeft"></Box>
      <SafetyInfoPageHelper
        firstInfoLine="before we start"
        secondInfoLine="Please check that there is"
        thirdInfoLine=" no Chair / table / object"
        forthInfoLine="during training !"
        subtitleLine="At the end of the workout"
        secondsubtitleLine=" You will receive another prize!"
        thirdsubtitleLine={null}
        direction="ltr"
        buttonLine="Lets Practice"
      />
    </Box>
  );
};

export default SafetyInfoPage;
