import { createSlice } from '@reduxjs/toolkit';

export const workoutSlice = createSlice({
  name: 'workout',
  initialState: {
    didWorkout: false,
  },
  reducers: {
    setDidWorkout: (state, action) => {
      state.didWorkout = action.payload;
    },
    resetWorkoutState: (state) => {
      state.didWorkout = false;
    },
  },
});

export const { setDidWorkout, resetWorkoutState  } = workoutSlice.actions;
export default workoutSlice.reducer;