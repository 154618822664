export const home = '/';
export const prizeInfoPage =
  home + 'InfoPage/prizeInfoPage/prizeInfoPage/PrizeInfoPage';
export const loginPage = home + 'login';
export const Questions = home + 'Questions/QuestionSetter/QuestionSetter';
export const WelcomePage = home + 'welcomePage/welcomePage';
export const Workout = home + 'Workout';
export const SafetyInfoPage = home + 'beforeWorkout';
export const WorkoutDone = home + 'WorkoutDone';
export const RegisterTrainingPage = home + 'RegisterTrainingPage';
export const DoneForTodayPage = home + 'DoneForTodayPage';
export const LanguageButton = home + 'LanguageButton/LanguageButton';
export const PrizesPage = home + 'PrizesPage/PrizesPage';
export const AdminPage = home + 'AdminPage';
