import './LoginStyle.css';
import {useDispatch} from 'react-redux';
import {login} from '../../store/authSlice';
import constant from './constants';
import axios from 'axios';
import {useNavigate} from 'react-router-dom';
import {Box, Button, IconButton, Image} from '@chakra-ui/react';

import {useGoogleLogin} from '@react-oauth/google';
import {useEffect, useState} from 'react';
import {useApiClient} from '../../api';
import googleLogo from './Logo-google-icon-PNG.png';

const GoogleLoginBtn = ({isLoggingIn}: {isLoggingIn: boolean}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  let baseApi: string;

  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    baseApi = require('../../config/config.local.json').BASE_API;
  } else {
    baseApi = require('../../config/config.json').BASE_API;
  }

  const onSuccess = async (res) => {
    setIsLoading(true);

    try {
      const response = await axios({
        url: `${baseApi}/auth/navigate`,
        method: 'POST',
        data: {code: res.code},
        headers: res.tokenId
          ? {
              Authorization: `Bearer ${res.tokenId}`,
            }
          : {},
      });
      localStorage.setItem('accessToken', response.data.idToken);
      localStorage.setItem('refreshToken', response.data.refreshToken);
      const {user, firstTime} = response.data;
      dispatch(
        login({
          id: user.id,
          name: user.name,
          email: user.email,
          provider: constant.GOOGLE,
          firstTime: firstTime,
          role: user.role,
        })
      );
      setIsLoading(false);
      navigate('/introduction');
    } catch (error) {
      setIsLoading(false);
    }
  };

  const googleLogin = useGoogleLogin({
    onSuccess,
    flow: 'auth-code',
  });

  return (
    <Button
      shadow={'xs'}
      backgroundColor={'white'}
      isLoading={isLoading || isLoggingIn}
      leftIcon={
        <IconButton
          aria-label=""
          width={'20px'}
          height={'20px'}
          backgroundColor={'transparent'}
          icon={
            <Image
              src={googleLogo}
              width={'20px'}
              backgroundColor={'transparent'}
            />
          }
        />
      }
      onClick={() => googleLogin()}
    >
      Login with Google
    </Button>
  );
};

export default GoogleLoginBtn;
