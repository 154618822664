import React from 'react';
import './AudioPlayer.css';
import littleJimi from '../images/littleJimi.png';
import {Image} from '@chakra-ui/react';

export const AudioPlayer = (props) => {
  return props.direction === 'rtl' ? (
    <Image
      src={littleJimi}
      onClick={() => new Audio(props.audioLink).play()}
      className="audioPlayerRight clickable"
      alt=""
    ></Image>
  ) : (
    <Image
      src={littleJimi}
      onClick={() => new Audio(props.audioLink).play()}
      className="audioPlayerLeft clickable"
      alt=""
    ></Image>
  );
};
