import axios, { AxiosError } from "axios";
import { identity } from "lodash";
import { NavigateFunction, useNavigate } from "react-router-dom";

let baseApi;

if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  baseApi = require("./config/config.local.json").BASE_API;
} else {
  baseApi = require("./config/config.json").BASE_API;
}

const refreshAxiosInstance = axios.create();

const getAxiosInstance = ({ navigate }: { navigate: NavigateFunction }) => {
  const axiosInstance = axios.create();

  const axiosResponseErrorRetryInterceptor: Parameters<
    typeof axiosInstance.interceptors.response.use
  >[1] = async (error: AxiosError) => {
    const config = error.config as {
      _retried: boolean;
      headers: Record<string, string>;
    };
    const isUnauthorized = error.response?.status === 401;

    if (!isUnauthorized || config._retried) {
      return Promise.reject(error);
    }

    const refreshToken = localStorage.getItem("refreshToken");

    if (isUnauthorized && !refreshToken) {
      localStorage.removeItem("accessToken");
      navigate("/login");
    }

    const response = await refreshAxiosInstance.post<
      {},
      { data: { id_token: string } }
    >(`${baseApi}/auth/refresh`, {
      refreshToken,
    });

    const idToken = response.data.id_token;

    localStorage.setItem("accessToken", idToken);

    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${idToken || ""}`,
    };
    config._retried = true;
    return axiosInstance(config);
  };

  axiosInstance.interceptors.request.use((config) => {
    if (!config.headers) {
      config.headers = {};
    }

    const accessToken = localStorage.getItem("accessToken");

    if (accessToken) {
      const authorizationHeader = `Bearer ${accessToken}`;
      config.headers.Authorization = authorizationHeader;
    }

    return config;
  });

  axiosInstance.interceptors.response.use(
    identity,
    axiosResponseErrorRetryInterceptor
  );

  return axiosInstance;
};

export const useApiClient = () => {
  const navigate = useNavigate();

  const axiosInstance = getAxiosInstance({ navigate });

  return {
    getQuestions: () => {
      return axiosInstance
        .get(`${baseApi}/getQuestions`)
        .then((res) => res.data)
        .catch((error) => console.error("Error on getQuestions", error));
    },
    sendUserInfo: (id: number, answers) => {
      return axiosInstance
        .post(`${baseApi}/auth/update/${id}`, answers)
        .then((res) => res.data)
        .catch((error) => console.error("Error on sendUserInfo", error));
    },
    sendUserTags: (id: number, answers) => {
      return axiosInstance
        .post(`${baseApi}/createDisabilities/${id}`, answers)
        .then((res) => res.data)
        .catch((error) => console.error("Error on sendUserTags", error));
    },
    getAllVideos: () => {
      return axiosInstance
        .get(`${baseApi}/getAllVideos`)
        .then(({ data }) => data)
        .catch((error) => console.error("Error on videos api call", error));
    },
    getAllUsers: () => {
      return axiosInstance
        .get(`${baseApi}/all-users`)
        .then(({ data }) => data)
        .catch((error) => console.error("Error on getAllUsers", error));
    },
    getFirstSession: (id: number) => {
      return axiosInstance
        .get(`${baseApi}/getFirstSession`, { params: { id } })
        .then(({ data }) => data)
        .catch((error) => console.error("Error on getFirstSession", error));
    },
    getUserVideos: () => {
      return axiosInstance
        .get(`${baseApi}/getVideosByUserId`)
        .then(({ data }) => data)
        .catch((error) => console.error("Error on getVideos", error));
    },
    logTraining: (id: number, count: number, disabilities) => {
      return axiosInstance
        .post(`${baseApi}/logTraining/${id}`, { count, disabilities })
        .then(({ data }) => data)
        .catch((error) => console.error("Error on logTraining", error));
    },
    deleteUser: (email: string) => {
      return axiosInstance
        .delete(`${baseApi}/user`, { params: { email } })
        .then(({ data }) => data)
        .catch((error) => console.error("Error on deleteUser", error));
    },
    getLogs: (id: number) => {
      return axiosInstance
        .get(`${baseApi}/getLogs`, { params: { id } })
        .then(({ data }) => data)
        .catch((error) => console.error("Error on getLogs", error));
    },
    postEndOfWorkOut: (id: number) => {
      return axiosInstance
        .post(`${baseApi}/workoutDone/${id}`)
        .then(({ data }) => data)
        .catch((error) => console.error("Error on EndOfWorkout", error));
    },
    postAuthNavigate: ({
      code,
      idToken,
    }: {
      code?: string;
      idToken?: string;
    }) => {
      return axiosInstance
        .post(`${baseApi}/auth/navigate`, { code, idToken })
        .then((res) => res.data)
        .catch((error) => console.error("Error on authNavigate", error));
    },
  };
};
