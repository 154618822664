import React from "react";
import "../workoutDonePage.css";
import littleJimi from "../../images/littleJimi.png"

export const AudioPlayer = (props) => {
  return props.direction === "rtl" ? (
    <img
      src={littleJimi}
      onClick={()=>new Audio(props.audioLink).play()}
      className="audioPlayerRightWorkout"
      alt=""
    ></img>
  ) : (
    <img
      src={littleJimi}
      onClick={()=>new Audio(props.audioLink).play()}
      className="audioPlayerLeftWorkout"
      alt=""
    ></img>
  );
};
