import React, {useState, useEffect} from 'react';
import './YesOrNoQuestionItem.css';
import nextEnglish from '../images/nextQuestionEnglish.png';
import nextMarked from '../images/nextQuestion.png';
import Jimi from '../images/Jimi3.png';
import yes from './images/yesButton.png';
import yesClicked from './images/yesButtonPurple.png';
import no from './images/noButton.png';
import noClicked from './images/noButtonPurple.png';
import yesEnglish from './images/yesButtonEnglish.png';
import yesClickedEnglish from './images/yesButtonPurpleEnglish.png';
import noEnglish from './images/noButtonEnglish.png';
import noClickedEnglish from './images/noButtonPurpleEnglish.png';
import {useIsMobile} from '../../../hooks/useIsMobile';

export const YesOrNoQuestionItem = (props) => {
  const [clickedYes, setClickedYes] = useState(false);
  const [next, setNext] = useState(false);
  const [clickedNo, setClickedNo] = useState(false);
  const [reset, setReset] = useState(false);

  React.useEffect(() => {
    if (reset) {
      setClickedNo(false);
      setClickedYes(false);
      setReset(false);
    }
  }, [reset]);

  const isMobile = useIsMobile();

  return (
    <div key={props.info.id} dir={props.direction}>
      {props.firstTime && !isMobile ? (
        <img
          src={props.info.progressBar}
          alt="top"
          className="top horizantalAlign"
        />
      ) : null}
      <div>
        {props.info.title === '' ? (
          <>
            <div className="horizantalAlign">
              <img
                className="jimi"
                src={Jimi}
                alt="Jimi"
                onClick={props.handleAudio}
              />
              <h5 className="question"> {props.info.boolQuestion}</h5>
            </div>
          </>
        ) : (
          <>
            <div className="verticalAlign horizantalAlign">
              <img
                className="jimi"
                src={Jimi}
                alt="Jimi"
                onClick={props.handleAudio}
              />
              <h5 className="title"> {props.info.title}</h5>
            </div>
            <h6 className="subtitle">{props.info.subTitle}</h6>
            <h5 className="question">{props.info.boolQuestion}</h5>
          </>
        )}
      </div>

      <img
        className="yes-no-button"
        src={
          props.direction === 'rtl'
            ? clickedYes
              ? yesClicked
              : yes
            : clickedYes
            ? yesClickedEnglish
            : yesEnglish
        }
        alt="yes"
        onClick={() =>
          props.handleYesOrNo(
            'Yes',
            props.info.id,
            setClickedYes,
            setNext,
            setClickedNo
          )
        }
      />
      <img
        className="yes-no-button"
        src={
          props.direction === 'rtl'
            ? clickedNo
              ? noClicked
              : no
            : clickedNo
            ? noClickedEnglish
            : noEnglish
        }
        alt="no"
        onClick={() =>
          props.handleYesOrNo(
            'No',
            props.info?.id,
            setClickedYes,
            setNext,
            setClickedNo
          )
        }
      />

      <div>
        {clickedYes ? (
          <div className="selection" dir={props.direction}>
            <p className="sec-question">{props.info.selectQuestion}</p>
            <select
              className="multi-choice"
              onChange={(e) => {
                props.handleSelect(e.target.value, props.info.id, setNext);
              }}
            >
              {props.info.selectOptions.map((option, value) => (
                <option key={value}>{option}</option>
              ))}
            </select>
          </div>
        ) : null}
      </div>
      <img
        className={next ? 'next-question' : 'next-unclicked'}
        src={props.direction === 'rtl' ? nextMarked : nextEnglish}
        alt="next-question"
        onClick={() => props.handleNext(next, setNext, setReset, true)}
      />
    </div>
  );
};
