import React from 'react';
import {useState} from 'react';
import nextEnglish from '../images/nextQuestionEnglish.png';
import nextMarked from '../images/nextQuestion.png';
import Jimi from '../images/Jimi3.png';
import './InputQuestionItem.css';
import {useIsMobile} from '../../../hooks/useIsMobile';
import {Box} from '@chakra-ui/react';

export const InputQuestionItem = (props) => {
  const [next, setNext] = useState(false);
  const [reset, setReset] = useState(false);
  const isMobile = useIsMobile();

  return (
    <Box key={props.info.id} padding={'16px'}>
      {props.firstTime && !isMobile ? (
        <img
          src={props.info.progressBar}
          alt="top"
          className="top horizantalAlign"
        />
      ) : null}
      <Box className="question-title" dir={props.direction}>
        <img
          className="jimi"
          src={Jimi}
          alt="Jimi"
          onClick={props.handleAudio}
        />
        <h4 className="question"> {props.info.question}</h4>
      </Box>

      <Box className="rowContainer" marginTop={'36px'}>
        {props.direction === 'rtl' ? (
          <Box className="measure">{props.info.measure}</Box>
        ) : null}
        <Box>
          <input
            type={props.info.inputType}
            min="0"
            step="1"
            className="input"
            onChange={(e) => {
              props.handleInput(e.target.value, props.info.id, setNext);
            }}
          />
        </Box>
        {props.direction === 'ltr' ? (
          <Box className="measure">{props.info.measure}</Box>
        ) : null}
      </Box>
      <h1 className="response" dir={props.info.dir} style={{marginTop: '24px'}}>
        {props.info.text}
      </h1>
      <img
        className={next ? 'next-question' : 'next-unclicked'}
        src={props.direction === 'rtl' ? nextMarked : nextEnglish}
        alt="next-question"
        onClick={() => props.handleNext(next, setNext, setReset, true)}
      />
    </Box>
  );
};
