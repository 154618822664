import './introduction.css';
const littleJimi = require('./images/littleJimi.png');
const contHebrew = require('./images/cont.png');
const contEnglish = require('./images/contEnglish.png');
const hebrewAudio = require('./audio/firstInstructionPageAudio.m4a');
import APPLICATION_CONSTANTS from '../../strings';
import { useDispatch } from 'react-redux';
import {useEffect, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import * as Constants from '../../Const';
import { useSelector } from 'react-redux';
import { setDidWorkout } from '../../store/workoutSlice';

import {
  Box,
  Button,
  Center,
  Container,
  Image,
  Text,
  TextProps,
} from '@chakra-ui/react';
import {useIsMobile} from '../../hooks/useIsMobile';

const messageByLang = {
  rtl: {
    clickOnAudio: 'בכל מסך ניתן ללחוץ על הפרצוף שלי',
    readTextMessage: 'ואני אקריא לכם את הטקסט',
    continueButton: contHebrew,
    redoButton: APPLICATION_CONSTANTS.redoHebrew,
  },
  ltr: {
    clickOnAudio: 'In each screen you can click on my face',
    readTextMessage: 'and I will read the text for you',
    continueButton: contEnglish,
    redoButton: APPLICATION_CONSTANTS.redoEnglish,
  },
};

const Introduction = (props: {direction: 'rtl' | 'ltr'}) => {
   const didWorkout = useSelector((state) => state.workout.didWorkout);

  const playAudio = () => {
    new Audio(hebrewAudio).play();
  };

  const [lang, setLang] = useState({
    clickOnAudio: 'text 1',
    readTextMessage: 'text 2',
    continueButton: contHebrew,
    redoButton: contHebrew,
  });

  useEffect(() => {
    setLang(messageByLang[props.direction]);
  }, [props.direction]);

  const navigate = useNavigate();

  const isMobile = useIsMobile();

  const onContinue = () => {
    navigate(Constants.Questions);
  };

  const onRedo = () => {
    navigate(Constants.Workout);
  };

  const commonFontStyle: TextProps = {
    fontFamily: 'Arimo',
    fontStyle: 'normal',
    fontWeight: 'bold',
    color: '#313636',
    textAlign: 'center',
  };

  const fontStyle = isMobile
    ? {
        ...commonFontStyle,
        fontSize: '20px',
      }
    : {
        ...commonFontStyle,
        fontSize: '25px',
      };

  return (
    <Center flexDir={'column'}>
      <Text marginTop={100} {...fontStyle}>
        {lang.clickOnAudio}
      </Text>
      <Center boxSize={150}>
        <Image
          cursor={'pointer'}
          alt="little-jimi"
          src={littleJimi}
          onClick={playAudio}
        ></Image>
      </Center>
      <Text {...fontStyle}>{lang.readTextMessage}</Text>
      {didWorkout ? (
      <Center boxSize={200}>

    <Button
          dir="ltr"
          className="RedoButton"
          backgroundColor={'#7b6ad5'}
          cursor={'pointer'}
          color={'white'}
          size={'lg'}
          onClick={onRedo}
          _hover={{bg: '#7b6ad5', color: 'white'}}
        >{lang.redoButton}</Button>


      </Center>
    ) : (
      <Center boxSize={200}>
        <Image
          cursor={'pointer'}
          alt="continue"
          src={lang.continueButton}
          onClick={onContinue}
        />
      </Center>
    )}
    </Center>
  );
};
export default Introduction;
