import React from 'react';
import {useNavigate} from 'react-router-dom';
import {AudioPlayer} from '../AudioPlayer/AudioPlayer';
import firstAudio from '../audio/before-we-begin.m4a';
import jimiImage from '../images/jimi-says-stop.png';
import {Box, Button, Center, Container, Image} from '@chakra-ui/react';
import {useIsMobile} from '../../../hooks/useIsMobile';

export const SafetyInfoPageHelper = (props) => {
  const navigate = useNavigate();
  const handleClick = (place) => {
    navigate(place);
  };

  const isMobile = useIsMobile();

  return props.direction === 'rtl' ? (
    <Box>
      <Box className="safetyInfoPage defaultText" padding={isMobile ? 50 : 100}>
        <Box>
          <Box>
            <Box className="firstLineContainer" dir="rtl">
              <AudioPlayer audioLink={firstAudio} direction="rtl" />
              <h5 className="firstLinesafetyLeft">{props.firstInfoLine}</h5>
            </Box>
            <h5 className="secondLinesafetyLeft">{props.secondInfoLine}</h5>
            <h5 className="secondLinesafetyLeft">{props.thirdInfoLine}</h5>
            <h5 className="secondLinesafetyLeft">{props.forthInfoLine}</h5>
            <h5 className="subtitlesafetyLeft">{props.subtitleLine}</h5>
            <h5 className="subtitlesafetyLeft">{props.secondsubtitleLine}</h5>
          </Box>
        </Box>
        <Image src={jimiImage} alt="" className="requiredJimiImagesafety" />
      </Box>
      <Center>
        <Button
          dir="rtl"
          className="safetyDoneConbutton"
          onClick={() => handleClick('../workout')}
          backgroundColor={'#7b6ad5'}
          color={'white'}
          size={'lg'}
          _hover={{bg: '#7b6ad5', color: 'white'}}
        >
          {props.buttonLine}
        </Button>
      </Center>
    </Box>
  ) : (
    <Box className="safetyInfoPage defaultText" padding={isMobile ? 50 : 100}>
      <Box className="horizantalAlighn">
        <Box>
          <Box className="firstLineContainer">
            <AudioPlayer audioLink={firstAudio} direction="ltr" />
            <h5 className="firstLinesafetyLeft">{props.firstInfoLine}</h5>
          </Box>
          <h5 className="secondLinesafetyLeft">{props.secondInfoLine}</h5>
          <h5 className="secondLinesafetyLeft">{props.thirdInfoLine}</h5>
          <h5 className="secondLinesafetyLeft">{props.forthInfoLine}</h5>
          <h5 className="subtitlesafetyLeft">{props.subtitleLine}</h5>
          <h5 className="subtitlesafetyLeft">{props.secondsubtitleLine}</h5>
        </Box>
      </Box>
      <Image src={jimiImage} alt="" className="requiredJimiImagesafety" />
      <Center>
        <Button
          dir="ltr"
          className="safetyDoneConbutton"
          onClick={() => handleClick('../workout')}
          backgroundColor={'#7b6ad5'}
          color={'white'}
          size={'lg'}
          _hover={{bg: '#7b6ad5', color: 'white'}}
        >
          {props.buttonLine}
        </Button>
      </Center>
    </Box>
  );
};
