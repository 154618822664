import React, { useState, useEffect } from 'react';
import {
  Box,
  Link,
  Input,
  Button,
  VStack,
  HStack,
  Text,
  Checkbox,
  Heading,
  useToast,
  Accordion,
  AccordionItem,
  AccordionPanel,
  AccordionButton,
  AccordionIcon,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogCloseButton,
  Center,
} from '@chakra-ui/react';
import {useApiClient} from '../../api';
import {useNavigate} from 'react-router-dom';
import * as Constants from '../../Const';

const AdminUsers =  () => {
  const api = useApiClient();
  const [searchTerm, setSearchTerm] = useState('');
  const [usersList, setUsersList] = useState<Object[]>([]);
  const toast = useToast();
  const [UsersAmount, setUsersAmount] = useState(0);
  const [selectedUserId, setSelectedUserId] = useState<number|null>(null);
  const [selectedUser, setSelectedUser] = useState<string|null>(null);
  const onOpen = (userId: number, userEmail:string) => {
    setSelectedUserId(userId);
    setSelectedUser(userEmail);
  }
  const onClose = () =>{
    setSelectedUserId(null);
    setSelectedUser(null)
  } 
  const navigate = useNavigate();

  const onLogsClick = (id: number) => {
    navigate('../../UserPage/UserPage', { state: { userId: id } });
  };
  const buttonRef = React.useRef<HTMLButtonElement>(null);

  useEffect(() => {
    const loadUsers = async () => {
      try {
        const users = await api.getAllUsers();
        setUsersList(users);
        setUsersAmount(users.length);
      } catch (err) {
        console.error(err);
      } finally {
       
      }
    };
    loadUsers();
  }, []);


  const handleSearch = () => {
    const filteredusers = usersList.filter(user => 
      user.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setUsersList(filteredusers);
  };

  const handleAttributeChange = (id : number, attribute) => {
    setUsersList(prevVideos => 
      prevVideos.map(video => 
        video.id === id ? { ...video, [attribute]: !video[attribute] } : video
      )
    );


    toast({
      title: 'User updated',
      description: "The User's attributes have been updated.",
      status: 'success',
      duration: 2000,
      isClosable: true,
    });
  };


  const onDeleteClick = (email: string) => {
    onClose();

    const deleteUser = async () => {
      try {
        await api.deleteUser(email);
        const users = await api.getAllUsers();
        setUsersList(users);
        setUsersAmount(users.length);
      } catch (err) {
        console.error(err);
      } finally {

      }
    };
    deleteUser();

    toast({
      title: 'User ' + email + ' deleted',
      description: "The User's have been deleted from database.",
      status: 'success',
      duration: 3000,
      isClosable: true,
    });
      };


  return (
    <Box p={5}>
      <Center>
      <Heading className='main-header' mb={5}>User Management</Heading>
      </Center>


      <HStack mb={5}>
        <Input 
          placeholder="Search users..." 
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <Button onClick={handleSearch}>Search</Button>
       
      </HStack>
      <Text>Total users: {UsersAmount}</Text>
      <VStack align="stretch" spacing={4}>
      <Accordion allowMultiple>
        {usersList.map(user => (
          

<AccordionItem key={user.id} borderWidth={3} borderRadius="md">
<AccordionButton>
 
            <Text fontWeight="bold">{user.name}</Text>
            <Text>Email: {user.email}</Text>

            <Button onClick={ () =>onOpen(user.id, user.email)}>Delete</Button>
            <Button onClick={ () =>onLogsClick(user.id)}>Logs</Button>

            <AlertDialog
              motionPreset='slideInBottom'
              leastDestructiveRef={buttonRef}
              onClose={onClose}
              isOpen={selectedUser !== null}
              isCentered
            >
              <AlertDialogOverlay />
      
              <AlertDialogContent>
                <AlertDialogHeader>Delete User?</AlertDialogHeader>
                <AlertDialogCloseButton />
                <AlertDialogBody>
                  Are you sure you want to delete user {selectedUser} from database, this action can't be reversed!
                </AlertDialogBody>
                <AlertDialogFooter>
                  <Button ref={buttonRef} onClick={onClose}>
                    No
                  </Button>
                  <Button colorScheme='red' ml={3} onClick={ () => onDeleteClick(selectedUser)}>
                    Yes
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialog>
          <AccordionIcon />
</AccordionButton>
          <AccordionPanel pb={4}>
          <Text>Gender: {user.gender}</Text>
          <Text>Weight: {user.weight}</Text>
          <Text>Height: {user.height}</Text>

          </AccordionPanel>
</AccordionItem>
        ))}
        </Accordion>
      </VStack>
    </Box>
  );


};
export default AdminUsers;






