import React, { useEffect, useState } from "react";
import "./FirstPrizeInfoPage.css";
import { AudioPlayer } from "../AudioPlayer/AudioPlayer";
import { Images } from "../ImageJimi/ImageJimi";
import heartImage from "../images/heart.png";
import jimiFirstPageImage from "../images/JimiFirstPrize.png";
import firstAudio from "../audio/first-prize.m4a";
import { PrizePageHelper } from "./prizePageHelper";

export const FirstPrizeInfoPage = (props) => {
  return props.direction === "rtl" ? (
    <div className="firstPrizePage">
      <PrizePageHelper
        firstInfoLine="!כל הכבוד "
        secondInfoLine="הגעתם עד לפה "
        direction="rtl"
        buttonLine="לשאלה הבאה"
        className="continuebutton"
        Image={jimiFirstPageImage}
        handleNext={props.handleNext}
        prizeUpdateStatus={props.prizeUpdateStatus}
        audioLink={firstAudio}
        prizeImage={heartImage}
      />
    </div>
  ) : (
    <div className="firstPrizePage">
      <PrizePageHelper
        firstInfoLine="Well done! "
        secondInfoLine="You have come this far "
        direction="ltr"
        buttonLine="Next"
        handleNext={props.handleNext}
        Image={jimiFirstPageImage}
        audioLink={firstAudio}
        prizeImage={heartImage}
      />
    </div>
  );
};
