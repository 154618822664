import {Navigate, Outlet} from "react-router-dom";
import * as Constants from '../Const'
import { useLocation } from "react-router-dom";

const ProtectedRoutes = (props) => {
    const location = useLocation();
    const isAuthenticated = props.auth.isAuthenticated();
    return isAuthenticated ? <Outlet /> : <Navigate to={Constants.loginPage} replace state={{from: location}}/>
}

export default ProtectedRoutes;