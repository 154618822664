import React, {useEffect, useState} from 'react';
import './FirstPrizeInfoPage.css';
import nextMarked from '../../Questions/images/nextQuestion.png';
import nextEnglish from '../../Questions/images/nextQuestionEnglish.png';
import {useApiClient} from '../../../api';
import {useSelector} from 'react-redux';
import littleJimi from '../images/littleJimi.png';
export const PrizePageHelper = (props) => {
  const [next, setNext] = useState(false);
  const [updatePrize, setUpdatePrize] = useState(false);
  const user = useSelector((state) => state.user.value);
  const {id} = user;

  const handleClickInfopage = (props) => {
    setUpdatePrize(props.prizeUpdateStatus);
    setTimeout(() => {
      props.handleNext(true, setNext, setNext, true);
    }, 500);
  };

  const api = useApiClient();

  useEffect(() => {
    const sendUpdatePrize = async () => {
      await api.postUpdatePrize(id);
    };
    if (updatePrize) {
      sendUpdatePrize();
    }
  }, [updatePrize, id]);

  return props.direction === 'rtl' ? (
    <div className="firstprizePage">
      <div className="flex-box-prize-info-page">
        <div className="flex-item-prize-info-page">
          <img
            src={littleJimi}
            onClick={() => new Audio(props.audioLink).play()}
            className="firstPrizeAudio"
            alt=""
          />
        </div>
        <div className="flex-item-prize-info-page">
          <h5 className="firstPrizeLineRight">{props.firstInfoLine}</h5>
        </div>
        <div className="flex-item-prize-info-page">
          <h5 className="secondPrizeLineRight">{props.secondInfoLine}</h5>
        </div>
        <div className="flex-item-prize-info-page">
          <img src={props.prizeImage} alt="" className="prizeImage" />
        </div>
        <div className="flex-item-prize-info-page">
          <img
            className="prizeConbutton"
            src={props.direction === 'rtl' ? nextMarked : nextEnglish}
            alt=""
            onClick={() => {
              handleClickInfopage(props);
            }}
          />
        </div>
      </div>
      <div className="flex-pic-prize-info-page">
        <img src={props.Image} alt="" className="requiredJimiImage" />
      </div>
    </div>
  ) : (
    <div className="firstprizePage">
      <div className="flex-box-prize-info-page">
        <div className="flex-item-prize-info-page">
          <img
            src={littleJimi}
            onClick={() => new Audio(props.audioLink).play()}
            className="firstPrizeAudioLeft"
            alt=""
          />
        </div>
        <div className="flex-item-prize-info-page">
          <h5 className="firstPrizeLineLeft">{props.firstInfoLine}</h5>
        </div>
        <div className="flex-item-prize-info-page">
          <h5 className="secondPrizeLineLeft">{props.secondInfoLine}</h5>
        </div>
        <div className="flex-item-prize-info-page">
          <img src={props.prizeImage} alt="" className="prizeImage" />
        </div>
        <div className="flex-item-prize-info-page">
          <img
            className="prizeConbuttonLeft"
            src={props.direction === 'rtl' ? nextMarked : nextEnglish}
            alt=""
            onClick={() => {
              handleClickInfopage(props);
            }}
          />
        </div>
      </div>
      <div className="flex-pic-prize-info-page">
        <img src={props.Image} alt="" className="requiredJimiImage" />
      </div>
    </div>
  );
};
