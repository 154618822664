import {useEffect, useMemo, useRef, useState} from 'react';
import './doneForTodayPage.css';
import {AudioPlayer} from '../AudioPlayer/AudioPlayer';
import jimiImage from '../images/jimi-bubble.png';
import firstAudio from '../audio/doneForToday.m4a';
import {Center, Container, Image, Text} from '@chakra-ui/react';
import {useNavigate} from 'react-router-dom';

function useInterval(callback: () => void, delay: number) {
  const savedCallback = useRef<() => void>();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current?.();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export const DoneForTodayPage = (props) => {
  const [timeForRedirect, setTimeForRedirect] = useState(5);
  const navigate = useNavigate();


  useInterval(() => {
    if (timeForRedirect >= 0) {
      setTimeForRedirect((prev) => prev - 1);
    }
  }, 1000);

  useEffect(() => {
    if (timeForRedirect === 0) {
      
      navigate('/introduction');
    }
  }, [timeForRedirect]);

  return (
    <Container alignContent={'center'}>
      <Center className="horizantalAlighn" marginTop={30}>
        <Container alignContent={'center'}>
          <Container variant={'vertical'}>
            <Center>
              <AudioPlayer audioLink={firstAudio} direction={props.direction} />
            </Center>
          </Container>
          <Text textAlign={'center'} fontSize={35} fontFamily={'Arimo'}>
            סיימנו להיום
          </Text>
          <Text
            textAlign={'center'}
            fontSize={35}
            fontWeight={1000}
            fontFamily={'Arimo'}
          >
            מחר יחכה לך אימון חדש
          </Text>
        </Container>
      </Center>
      <Center marginTop={50}>
        <Image width={130} src={jimiImage} />
      </Center>
      <Text textAlign={'center'} marginTop={'100px'}>
        תועברו למסך הבית בעוד {timeForRedirect} שניות
      </Text>
    </Container>
  );
};
