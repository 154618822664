import React from "react";
import "./SecondPrizeInfoPage.css";
import { AudioPlayer } from "../AudioPlayer/AudioPlayer";
import { Images } from "../ImageJimi/ImageJimi";
import clockImage from "../images/clock.png";
import jimiSecondPageImage from "../images/JimiSecondPrize.png";
import secondAudio from "../audio/second-prize.m4a";
import { PrizePageHelper } from "./prizePageHelper";
export const SecondPrizeInfoPage = (props) => {
  return props.direction === "rtl" ? (
    <div className="Page">
      <PrizePageHelper
        firstInfoLine="! יש"
        secondInfoLine=" סיימתם את השאלון "
        direction="rtl"
        buttonLine="לשאלה הבאה"
        handleNext={props.handleNext}
        Image={jimiSecondPageImage}
        prizeUpdateStatus={props.prizeUpdateStatus}
        audioLink={secondAudio}
        prizeImage={clockImage}
      />
    </div>
  ) : (
    <div className="Page">
      <PrizePageHelper
        firstInfoLine="Yai! "
        secondInfoLine="You have completed the questionnaire"
        direction="ltr"
        buttonLine="Next"
        handleNext={props.handleNext}
        Image={jimiSecondPageImage}
        audioLink={secondAudio}
        prizeImage={clockImage}
      />
    </div>
  );
};
