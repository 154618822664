import React from 'react';
import {useState, useEffect} from 'react';
import {QuestionPage} from '../QuestionPage/QuestionPage';
import {useApiClient} from '../../../api';
import {useNavigate} from 'react-router-dom';
import * as Constants from '../../../Const';
import {useSelector} from 'react-redux';
import { setDisabilities } from '../../../store/disabilitiesSlice';
import { useDispatch } from 'react-redux';

export const QuestionsSetter = (props) => {
  const api = useApiClient();
  const navigate = useNavigate();
  const [questionIndex, setQuestionIndex] = useState(0);
  const [questionsList, setQuestionsList] = useState([]);
  const [lastQuestion, setLastQuestion] = useState(false);
  const [answers, setAnswers] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const user = useSelector((state) => state.user.value);
  const dispatch = useDispatch();

  const fetchQuestions = async () => {
    setIsLoading(true);
    try {
      const res = await api.getQuestions();

      if (!user.firstTime) {
        const tempQuestionList = res.questionList.filter(
          (question) => question.appearance === 'secondary'
        );
        setQuestionsList(tempQuestionList);
      } else {
        setQuestionsList(res.questionList);
      }
    } catch (err) {
      console.error(err);
    }
    setIsLoading(false);
  };

  const sendAnswers = async (answers) => {
    dispatch(setDisabilities(answers));
    if (user.firstTime) {
      await api.sendUserInfo(user.id, answers);
    }
    await api.sendUserTags(user.id, answers);
  };

  useEffect(() => {
    fetchQuestions();
  }, []);

  useEffect(() => {
    if (lastQuestion) {
      sendAnswers(answers);
      navigate(Constants.WelcomePage);
    }
  }, [lastQuestion]);

  const isEnglish = ((props.direction === 'ltr') * questionsList.length) / 2;

  return isLoading ? null : (
    <QuestionPage
      question={questionsList[questionIndex + isEnglish]}
      setQuestionIndex={setQuestionIndex}
      questionIndex={questionIndex}
      size={questionsList.length / 2}
      setAnswers={setAnswers}
      setLastQuestion={setLastQuestion}
      direction={props.direction}
    />
  );
};
export default QuestionsSetter;

