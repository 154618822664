import React from 'react';
import './workoutDonePage.css';
import jimiImage from '../images/jimi-with-gift.png';
import {useNavigate} from 'react-router-dom';
import {WorkoutDonePageHelper} from './workoutDoneHelper';
const WorkoutDonePage = (props) => {
  const navigate = useNavigate();
  const handleClick = (place) => {
    navigate(place);
  };
  return props.direction === 'rtl' ? (
    <div className="Page" dir="rtl">
      <WorkoutDonePageHelper
        firstInfoLine=" !כל הכבוד "
        secondInfoLine="סיימת את האימון היום "
        thirdInfoLine="!  זכיתם בפרס נוסף"
        direction="rtl"
        Image={jimiImage}
        pageType="workoutPage"
        buttonLine="חזור לעמוד הראשי"
        nextPage="../../welcomePage/WelcomePage"
        continue={handleClick}
      />
    </div>
  ) : (
    <div className="Page" dir="ltr">
      <WorkoutDonePageHelper
        firstInfoLine=" Well done! "
        secondInfoLine="You're done training today"
        thirdInfoLine="You won another prize!"
        direction="ltr"
        Image={jimiImage}
        buttonLine="Return to main page"
        nextPage="../welcomePage/welcomePage"
        continue={handleClick}
      />
    </div>
  );
};

export default WorkoutDonePage;
