import React from 'react';
import {NavigateBar} from '../navigateBar/navigateBar';
import {useSelector} from 'react-redux';
import {Box, Flex} from '@chakra-ui/react';

export const NavAndButtonBar = (props) => {
  const user = useSelector((state) => state.user.value);
  const getNavAndButtonBar = () => {
    if (user.firstTime) {
      if (props.pageType === 'beforeWorkOut') {
        return props.includeButton === true ? (
          <>
            <NavigateBar
              direction={props.direction}
              navBarLink={props.navBarLink}
              onChangeToggle={props.onChangeToggle}
              checked={props.checked}
              includeLogoutButton={props.includeLogoutButton}
              backPageLink={props.backPageLink}
              includeJimmiButton={props.includeJimmiButton}
            />
          </>
        ) : (
          <NavigateBar
            direction={props.direction}
            navBarLink={props.navBarLink}
            onChangeToggle={props.onChangeToggle}
            checked={props.checked}
            includeLogoutButton={props.includeLogoutButton}
            backPageLink={props.backPageLink}
            includeJimmiButton={props.includeJimmiButton}
          />
        );
      } else {
        return props.includeButton ? (
          <>
            <NavigateBar
              direction={props.direction}
              navBarLink={props.navBarLink}
              onChangeToggle={props.onChangeToggle}
              checked={props.checked}
              includeLogoutButton={props.includeLogoutButton}
              backPageLink={props.backPageLink}
              includeJimmiButton={props.includeJimmiButton}
            />
          </>
        ) : (
          <NavigateBar
            direction={props.direction}
            navBarLink={props.navBarLink}
            onChangeToggle={props.onChangeToggle}
            checked={props.checked}
            includeLogoutButton={props.includeLogoutButton}
            backPageLink={props.backPageLink}
            includeJimmiButton={props.includeJimmiButton}
          />
        );
      }
    } else {
      return props.includeButton === true ? (
        <>
          <NavigateBar
            direction={props.direction}
            navBarLink={props.navBarLink}
            onChangeToggle={props.onChangeToggle}
            checked={props.checked}
            includeLogoutButton={props.includeLogoutButton}
            backPageLink={props.backPageLink}
            includeJimmiButton={props.includeJimmiButton}
          />
        </>
      ) : (
        <NavigateBar
          direction={props.direction}
          navBarLink={props.navBarLink}
          onChangeToggle={props.onChangeToggle}
          checked={props.checked}
          includeLogoutButton={props.includeLogoutButton}
          backPageLink={props.backPageLink}
          includeJimmiButton={props.includeJimmiButton}
        />
      );
    }
  };

  return <Box width={'100%'}>{getNavAndButtonBar()}</Box>;
};
