import React from 'react';
import './navigateBar.css';
const specialJimi = require('../images/jimi.png');
import {useNavigate} from 'react-router-dom';
import LogoutBtn from '../LogoutBtn/LogoutBtn';
import {Box, Button, Container, Flex, HStack, Image, Spacer} from '@chakra-ui/react';
import {useIsMobile} from '../../../hooks/useIsMobile';
import {BackButton} from '../BackButton/BackButton';
import {AdminButton } from '../AdminButton/AdminButton';
import {useSelector} from 'react-redux';

type NavigateBarProps = {
  direction: 'rtl' | 'ltr';
  navBarLink: string;
  onChangeToggle: any;
  checked: boolean;
  includeLogoutButton: boolean;
  backPageLink?: string;
  includeJimmiButton: boolean;
};

const isAdmin = (userRole: string) => userRole === "admin";  


export const NavigateBar = (props: NavigateBarProps) => {
  const navigate = useNavigate();
  const specialJimiImage = specialJimi;
  const isMobile = useIsMobile();
  const user = useSelector((state) => state.user.value);
  const role:string = user.role;  

  return (
    <HStack
      alignItems={'center'}
      flexDirection={props.direction === 'rtl' ? 'row' : 'row-reverse'}
      spacing={isMobile ? 1 : 4}
    >
      {props.includeLogoutButton && (
        <Box className="flex-item-navbar">
          <Box px={2}  p={2}>
          <LogoutBtn direction={props.direction} />
          </Box>
         
        {isAdmin(role) ? (
          <AdminButton lang={props.direction} />
        ) : (
          <div/>//Empty div, don't show the admin button when user is not admin
        )}
        </Box>
      )}

      <Spacer />
      {props.includeJimmiButton && (
        <Box marginRight={props.backPageLink ? 0 : 5}>
          <Image
            src={specialJimiImage}
            onClick={() => navigate(props.navBarLink)}
            alt=""
            height={37}
            cursor={'pointer'}
          />
        </Box>
      )}

      {props.backPageLink ? (
        <Box float={'right'}>
          <BackButton
            direction={props.direction}
            backPageLink={props.backPageLink}
          />
        </Box>
      ) : null}
    </HStack>
  );
};
