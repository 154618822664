import nextEnglish from '../images/nextQuestionEnglish.png';
import nextMarked from '../images/nextQuestion.png';
import './GenderQuestionItem.css';

import Jimi from '../images/Jimi3.png';
import male from './images/male.png';
import female from './images/female.png';
import React from 'react';
import {useState, useEffect} from 'react';
import {
  Box,
  Container,
  Image,
  Text,
  HStack,
  Flex,
  Center,
  Button,
} from '@chakra-ui/react';
import {useIsMobile} from '../../../hooks/useIsMobile';

export const GenderQuestionItem = (props) => {
  const [clickedMale, setClickedMale] = useState(-1);
  const [next, setNext] = useState(false);
  const [clickedFemale, setClickedFemale] = useState(-1);
  const [reset, setReset] = useState(false);

  React.useEffect(() => {
    if (reset) {
      setClickedMale(-1);
      setClickedFemale(-1);
      setReset(false);
    }
  }, [reset]);

  const isMobile = useIsMobile();

  function checkGender(clickedFemale, bordered) {
    if (clickedFemale === 1) {
      return bordered;
    } else if (clickedFemale === -1) {
      return 'regular';
    }
  }

  return (
    <Box dir={props.direction} key={props.info.id}>
      {!isMobile && (
        <Image src={props.info.progressBar} className="top horizantalAlign" />
      )}
      <div className="question-title verticalAlign horizantalAlign">
        <Image
          className="jimi"
          src={Jimi}
          alt="Jimi"
          onClick={props.handleAudio}
        />
        <h3 className="question" id="femaleText">
          {props.info.question}
        </h3>
      </div>
      <HStack direction={'row'} marginTop={'20px'}>
        <Center flex={1} width={'fit-content'} flexDirection={'column'}>
          <Image
            height={'220px'}
            id="female"
            className={checkGender(clickedFemale, 'borderedPurple') + ' female'}
            alt="female"
            src={female}
            onClick={() =>
              props.handleGenderQuestion(
                'female',
                props.info.id,
                setClickedMale,
                setNext,
                setClickedFemale
              )
            }
          />
          <Text fontSize={'24px'}>{props.info.options[1]}</Text>
        </Center>
        <Center className="male" flex={1} flexDirection={'column'}>
          <Image
            height={'220px'}
            id="male"
            className={checkGender(clickedMale, 'borderedGreen') + ' male'}
            alt="male"
            src={male}
            onClick={() =>
              props.handleGenderQuestion(
                'male',
                props.info.id,
                setClickedMale,
                setNext,
                setClickedFemale
              )
            }
          />
          <Text fontSize={'24px'}>{props.info.options[0]}</Text>
        </Center>
      </HStack>
      {next ? (
        <Text
          marginTop={'20px'}
          fontSize={'24px'}
          className={next ? 'regular' : 'hide'}
          id="dynamic-text-second"
        >
          {props.info.response}
        </Text>
      ) : null}
      <Button
        marginTop={'50px'}
        dir="rtl"
        onClick={() => props.handleNext(next, setNext, setReset, true)}
        backgroundColor={'#7b6ad5'}
        color={'white'}
        size={'lg'}
        _hover={{bg: '#7b6ad5', color: 'white'}}
        isDisabled={clickedFemale === -1 && clickedMale === -1}
      >
        לשאלה הבאה
      </Button>
    </Box>
  );
};
