import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./authSlice";
import workoutReducer from "./workoutSlice";
import disabilitiesReducer from "./disabilitiesSlice";

export const store = configureStore({
  reducer: {
    user: authReducer,
    workout: workoutReducer,
    disabilities:  disabilitiesReducer,
  }

});
