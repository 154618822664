import React from 'react';
import {useState, useEffect} from 'react';

import './GenericQuestionItem.css';
import nextEnglish from '../images/nextQuestionEnglish.png';
import nextMarked from '../images/nextQuestion.png';
import Jimi from '../images/Jimi3.png';
import {useIsMobile} from '../../../hooks/useIsMobile';
import {Box, HStack, Image, VStack} from '@chakra-ui/react';
export const GenericQuestionItem = (props) => {
  const [clickedAns, setClickedAns] = useState(-1);
  const [next, setNext] = useState(false);
  const [reset, setReset] = useState(false);

  React.useEffect(() => {
    if (reset) {
      setClickedAns(-1);
      setReset(false);
    }
  }, [reset]);

  const isMobile = useIsMobile();

  return (
    <div
      dir={props.direction}
      key={props.info.id}
      className="GenericContainer verticalAlign horizantalAlign"
    >
      <div>
        {' '}
        {props.firstTime && !isMobile ? (
          <img
            src={props.info.progressBar}
            alt="top"
            className="top horizantalAlign"
          />
        ) : null}
        {props.info.title === '' ? (
          <>
            <div className="horizantalAlign">
              <img
                className="jimi"
                src={Jimi}
                alt="Jimi"
                onClick={props.handleAudio}
              />
              <h5 className="question"> {props.info.question}</h5>
            </div>
          </>
        ) : (
          <>
            <HStack>
              <Image
                className="jimi"
                src={Jimi}
                alt="Jimi"
                onClick={props.handleAudio}
              />
              <h5 className="medicalTitle"> {props.info.title}</h5>
            </HStack>
            <h6 className="medicalSubtitle">{props.info.subTitle}</h6>
            <h5 className="question">{props.info.question}</h5>
          </>
        )}
      </div>

      <ul className="answers-list">
        {props.info.options.map((option, indexOfOption) => {
          return (
            <div
              className={indexOfOption === clickedAns ? 'purple' : 'white'}
              key={indexOfOption}
              onClick={() =>
                props.handleGenericQuestion(
                  props.info.optionsDescription[indexOfOption],
                  indexOfOption,
                  props.info.id,
                  setClickedAns,
                  setNext,
                  clickedAns
                )
              }
            >
              <p>{option}</p>
            </div>
          );
        })}
      </ul>

      <img
        className={next ? 'next-question' : 'next-unclicked'}
        src={props.direction === 'rtl' ? nextMarked : nextEnglish}
        alt="next-question"
        onClick={() => props.handleNext(next, setNext, setReset, true)}
      />
    </div>
  );
};
