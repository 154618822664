import React, { useState, useEffect } from 'react';
import {
  Box,
  Link,
  Input,
  Button,
  VStack,
  HStack,
  Text,
  Checkbox,
  Heading,
  useToast,
  Center,
} from '@chakra-ui/react';
import {useApiClient} from '../../api';

const AdminVideos =  () => {
  const api = useApiClient();
  const [searchTerm, setSearchTerm] = useState('');
  const [videos, setVideos] = useState<Object>([]);
  const toast = useToast();
  const [workoutVideos, setWorkoutVideos] = useState<String[]>([]);
  const [videoAmount, setVideoAmount] = useState(0);

  useEffect(() => {
    const loadVideos = async () => {
      try {
        const videos = await api.getAllVideos();
        setWorkoutVideos(videos);
        setVideoAmount(videos.length);
        setVideos(videos);
      } catch (err) {
        console.error(err);
      } finally {
      }
    };
    loadVideos();
  }, []);


  const handleSearch = () => {
    const filteredVideos = workoutVideos.filter(video => 
      video.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setVideos(filteredVideos);
  };

  const handleAttributeChange = (id, attribute) => {
    setVideos(prevVideos => 
      prevVideos.map(video => 
        video.id === id ? { ...video, [attribute]: !video[attribute] } : video
      )
    );

    toast({
      title: 'Video updated',
      description: "The video's attributes have been updated.",
      status: 'success',
      duration: 2000,
      isClosable: true,
    });
  };

  return (
    <Box p={5}>
      <Center>
      <Heading mb={5}>Video Management</Heading>
      </Center>



      <HStack mb={5}>
        <Input 
          placeholder="Search videos..." 
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <Button onClick={handleSearch}>Search</Button>
       
      </HStack>
      <Text>Total Videos: {videoAmount}</Text>
      <VStack align="stretch" spacing={4}>
        {videos.map(video => (
          <Box key={video.id} p={4} borderWidth={1} borderRadius="md">
            <Text fontWeight="bold">{video.name}</Text>
            <Link >https://www.youtube.com/watch?v={video.link}</Link>
            <Text>Major Muscle: {video.majorMuscle}</Text>
            <Text>Equipment: {video.equipment}</Text>
            
            <HStack mt={2}>
             
              <Checkbox 
                isChecked={video.noLegs}
                onChange={() => handleAttributeChange(video.id, 'noLegs')}
              >
                neck problems
              </Checkbox>
              <Checkbox 
                isChecked={video.noArms}
                onChange={() => handleAttributeChange(video.id, 'noArms')}
              >
                back problems
              </Checkbox>
              <Checkbox 
                isChecked={video.mentallyChallenged}
                onChange={() => handleAttributeChange(video.id, 'mentallyChallenged')}
              >
                Down's syndrome
              </Checkbox>
            </HStack>
          </Box>
        ))}
      </VStack>
    </Box>
  );


};
export default AdminVideos;






