import React, {useState} from 'react';
import checkBox from './images/checkbox.png';
import checked from './images/checked.png';
import nextEnglish from '../images/nextQuestionEnglish.png';
import nextMarked from '../images/nextQuestion.png';
import Jimi from '../images/Jimi3.png';
import './MultiSelectQuestionItem.css';
import {useIsMobile} from '../../../hooks/useIsMobile';

export const MultiSelectQuestionItem = (props) => {
  const [answers, setAnswers] = useState(new Array(props.info.options.length));
  const [next, setNext] = useState(false);
  const isMobile = useIsMobile();

  return (
    <div key={props.info.id}>
      {props.firstTime && !isMobile ? (
        <img
          src={props.info.progressBar}
          alt="top"
          className="top horizantalAlign"
        />
      ) : null}
      <div className="question-title" dir={props.direction}>
        <h5 className="title">
          {props.info.title !== '' ? (
            <img
              className="jimi"
              src={Jimi}
              alt="Jimi"
              onClick={props.handleAudio}
            />
          ) : null}
          {props.info.title !== '' ? props.info.title : null}
        </h5>
      </div>
      <h3 className="subtitle" dir={props.direction}>
        {props.info.subtitle !== '' ? props.info.subtitle : null}
      </h3>
      <div className="question-title" dir={props.direction}>
        {props.info.title === '' ? (
          <img
            className="jimi"
            src={Jimi}
            alt="Jimi"
            onClick={props.handleAudio}
          />
        ) : null}
        <h4 className="question"> {props.info.question}</h4>
      </div>
      <ul className="answers-list">
        {props.info.options.map((option, index) => {
          const check = answers[index] !== undefined ? checked : checkBox;
          return (
            <div className="answer-box" dir={props.direction} key={index}>
              <img
                className="check-box"
                src={check}
                onClick={() =>
                  props.handleMultiSelect(
                    props.info.id,
                    props.info.optionsDescription[index],
                    index,
                    answers,
                    setAnswers,
                    setNext
                  )
                }
                alt="checkbox"
              />
              <li key={index} className="answer" dir={props.direction}>
                {option}
              </li>
            </div>
          );
        })}
      </ul>
      <img
        className={next ? 'next-question' : 'next-unclicked'}
        src={props.direction === 'rtl' ? nextMarked : nextEnglish}
        alt="next-question"
        onClick={() => props.handleNext(next, setNext, setAnswers, [])}
      />
    </div>
  );
};
