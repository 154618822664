import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Provider} from 'react-redux';
import {store} from './store/store';
import {BrowserRouter} from 'react-router-dom';
import {ChakraProvider} from '@chakra-ui/react';
import {GoogleOAuthProvider} from '@react-oauth/google';
import {GOOGLE_CLIENT_ID} from './consts';

const root = ReactDOM.createRoot(document.getElementById('root')!);
root.render(
  <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
    <ChakraProvider>
      <React.StrictMode>
        <Provider store={store}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </Provider>
      </React.StrictMode>
    </ChakraProvider>
  </GoogleOAuthProvider>
);
reportWebVitals();
