import WelcomePage from './WelcomePage';
import {useSelector} from 'react-redux';

const WelcomePageGeneric = (props) => {
  const user = useSelector((state) => state.user.value);
  const shouldDisplayPrizesOption = false;

  return props.direction === 'rtl' ? (
    <WelcomePage
      dir={props.dir}
      myWorkout="האימון שלי"
      whoIsJimmi="מי זה ג'ימי"
      contact="צור קשר"
      message="לחץ/י על ג'ימי לעזרה קולית"
      name={user.name}
      welcomeMessage="ברוכ/ה הבא/ה "
      askWhatDoYouWant="מה תרצה/י לעשות היום?"
      listOfThings={[
        {val: 'להתחיל אימון חדש', picture: null, href: '../beforeWorkout'},
        ...(shouldDisplayPrizesOption
          ? []
          : []),
      ]}
    />
  ) : (
    <WelcomePage
      dir={props.dir}
      myWorkout="My workout"
      whoIsJimmi="Who is Jimmy"
      contact="Contact us"
      message="Click on Jimmy for voice help"
      name={user.name}
      welcomeMessage="Welcome "
      askWhatDoYouWant="What do you want to do today?"
      listOfThings={[
        {
          val: 'To start a new Workout',
          href: '../beforeWorkout',
          picture: null,
        }
      ]}
    />
  );
};
export default WelcomePageGeneric;
