import React, {useEffect, useState} from 'react';
import {useApiClient} from '../../api';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import YoutubeVideoPlayer from './YoutubeVideoPlayer/YoutubeVideoPlayer';
import {Box, Button, Center, Container, Image, Text} from '@chakra-ui/react';
import {ChevronLeftIcon, ChevronRightIcon} from '@chakra-ui/icons';
import {useIsMobile} from '../../hooks/useIsMobile';
import { useDispatch } from 'react-redux';
import { setDidWorkout } from '../../store/workoutSlice';

export const Workout = () => {
  const api = useApiClient();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.value);
  const {id} = user;
  const [videoIndex, setVideoIndex] = useState(0);
  const [workoutVideos, setWorkoutVideos] = useState([]);
  const disabilities = useSelector((state) => state.disabilities.answers);
  const [isLoadingVideos, setIsLoadingVideos] = useState(true);
  
  useEffect(() => {
    const updateWindow = async () => {
      try {
        const videos = await api.getUserVideos();
        setWorkoutVideos(videos);
        
        //Maybe remove await?
        await api.logTraining(id,videos.length,disabilities);
        
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoadingVideos(false);
      }
    };
    updateWindow();
  }, [id, disabilities]);


  const onClickNextVideo = () => {
    if (workoutVideos[videoIndex + 1]) {
      setVideoIndex(videoIndex + 1);
    } else {
      dispatch(setDidWorkout(true));
      setTimeout(() => {
        navigate('../WorkoutDone');
      }, 500);
    }
  };

  const onClickWorkoutDone = () => {
    dispatch(setDidWorkout(true));
    setTimeout(() => {
      navigate('../DoneForTodayPage');
    }, 500);
  };

  const onClickPrevVideo = () => {
    if (videoIndex > 0) {
      setVideoIndex(videoIndex - 1);
    }
  };

  const isMobile = useIsMobile();
  const isNextButtonEnabled = videoIndex < workoutVideos.length - 1;
  const isPrevButtonEnabled = videoIndex > 0;

  if (isLoadingVideos) {
    return <Center>Loading...</Center>;
  }

  if (workoutVideos.length === 0) {
    return (
      <Center>
        <Text
          fontFamily={'Arimo'}
          fontStyle={'normal'}
          fontWeight={'bold'}
          color={'#313636'}
          textAlign={'center'}
        >
          לא נמצאו סרטונים מתאימים עבור המשתמש שלך
        </Text>
      </Center>
    );
  }

  return (
    <Container {...(isMobile ? {} : {padding: 0})} paddingTop={50}>
      <Center>
        <YoutubeVideoPlayer videoId={workoutVideos[videoIndex]} />
      </Center>
      <Center padding={0} justifyContent={'space-between'}>
        <ChevronLeftIcon
          onClick={isNextButtonEnabled ? () => onClickNextVideo() : undefined}
          boxSize={16}
          color={isNextButtonEnabled ? 'purple.500' : 'lightgray'}
          cursor={isNextButtonEnabled ? 'pointer' : undefined}
        />
        <Center boxSize={120}>

        <Button
            dir="rtl"
            onClick={() => onClickWorkoutDone()}
            backgroundColor={'#7b6ad5'}
            color={'white'}
            size={'lg'}
            _hover={{bg: '#7b6ad5', color: 'white'}}
          >
            לסיום האימון
          </Button>


        </Center>
        <ChevronRightIcon
          onClick={isPrevButtonEnabled ? () => onClickPrevVideo() : undefined}
          boxSize={16}
          color={isPrevButtonEnabled ? 'purple.500' : 'lightgray'}
          cursor={isPrevButtonEnabled ? 'pointer' : undefined}
        />
      </Center>
    </Container>
  );
};
