import { createSlice } from '@reduxjs/toolkit';

export const disabilitiesSlice = createSlice({
  name: 'disabilitiesAnswers',
  initialState: {
    answers: [],
  },
  reducers: {
    setDisabilities: (state, action) => {
      state.answers = action.payload;
    },
    resetDisabilities: (state) => {
      state.answers = [];
    },
  },
});

export const {setDisabilities, resetDisabilities} = disabilitiesSlice.actions;
export default disabilitiesSlice.reducer;