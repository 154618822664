import { useState } from "react";
import { YesOrNoQuestionItem } from "../YesOrNoQuestionItem/YesOrNoQuestionItem";
import { MultiSelectQuestionItem } from "../MultiSelectQuestionItem/MultiSelectQuestionItem";
import { MultiPictureSelectQuestionItem } from "../MultiPictureSelectQuestionItem/MultiPictureSelectQuestionItem";
import { InputQuestionItem } from "../InputQuestionItem/InputQuestionItem";
import { GenericQuestionItem } from "../GenericQuestionItem/GenericQuestionItem";
import { GenderQuestionItem } from "../GenderQuestionItem/GenderQuestionItem";
import { FirstPrizeInfoPage } from "../../InfoPage/FirstPrizeInfoPage/FirstPrizeInfoPage";
import { SecondPrizeInfoPage } from "../../InfoPage/SecondPrizeInfoPage/SecondPrizeInfoPage";
import { useSelector } from "react-redux";

export const QuestionPage = (props) => {
  const [AnswersOfAllQuestions, setAnswers] = useState([]);
  const user = useSelector((state) => state.user.value);

  const getIndex = (value) => {
    let retValue = 0;
    props.question.selectOptions.map((option, index) => {
      if (option === value) {
        retValue = index;
      }
    });
    return retValue;
  };

  const handleGenderQuestion = (
    answer,
    questionID,
    setClickedMale,
    setNext,
    setClickedFemale
  ) => {
    const id = user.firstTime ? questionID : props.questionIndex;
    if (answer === "male") {
      setClickedMale(1);
      setClickedFemale(0);
    } else {
      setClickedFemale(1);
      setClickedMale(0);
    }
    setNext(true);
    const questionAnswer = [
      { appearance: props.question.appearance, [props.question.tag]: answer },
    ];
    const temp = AnswersOfAllQuestions;
    if (AnswersOfAllQuestions.length - 1 === id) {
      temp.pop();
    }
    const newAnswers = temp.concat(questionAnswer);
    setAnswers(newAnswers);
  };

  const handleGenericQuestion = (
    option,
    indexOfOption,
    questionID,
    setClickedAns,
    setNext,
    clickedAns
  ) => {
    const id = user.firstTime ? questionID : props.questionIndex;
    setClickedAns(indexOfOption);
    setNext(true);
    const questionAnswer = [
      { appearance: props.question.appearance, [props.question.tag]: option },
    ];
    const temp = AnswersOfAllQuestions;
    if (AnswersOfAllQuestions.length - 1 === id) {
      temp.pop();
    }
    const newAnswers = temp.concat(questionAnswer);
    setAnswers(newAnswers);
  };

  const setSelectAnswer = (answer, option, id) => {
    const questionAnswer = [
      {
        appearance: props.question.appearance,
        [props.question.firstTag]: answer,
        [props.question.secondTag]: option,
      },
    ];
    const tempAnswersArray = AnswersOfAllQuestions;
    if (AnswersOfAllQuestions.length - 1 === id) {
      tempAnswersArray.pop();
    }
    const newAnswers = tempAnswersArray.concat(questionAnswer);
    setAnswers(newAnswers);
  };

  const handleYesOrNo = (
    answer,
    questionID,
    setClickedYes,
    setNext,
    setClickedNo
  ) => {
    const id = user.firstTime ? questionID : props.questionIndex;
    if (answer === "Yes") {
      setClickedYes(true);
      setClickedNo(false);
      setNext(true);
      setSelectAnswer(answer, props.question.selectOptionsDescription[0], id);
    } else {
      setClickedYes(false);
      setClickedNo(true);
      setNext(true);
      setSelectAnswer(answer, "none", id);
    }
  };

  const handleSelect = (chosenOption, questionID, setNext) => {
    const id = user.firstTime ? questionID : props.questionIndex;
    let index = getIndex(chosenOption);
    const questionAnswer = [
      {
        appearance: props.question.appearance,
        [props.question.firstTag]: "Yes",
        [props.question.secondTag]:
          props.question.selectOptionsDescription[index],
      },
    ];
    const temp = AnswersOfAllQuestions;
    if (AnswersOfAllQuestions.length - 1 === id) {
      temp.pop();
    }
    const newAnswers = temp.concat(questionAnswer);
    setNext(true);
    setAnswers(newAnswers);
  };

  const handleSingleOption = (newAnswers, option, questionAnswers, setNext) => {
    if (
      questionAnswers.findIndex((e) => {
        return e === option;
      }) === -1
    ) {
      newAnswers[props.question.options.length - 1] = option;
      setNext(true);
    } else {
      newAnswers[props.question.options.length - 1] = undefined;
      setNext(false);
    }
  };

  const handleNotSingleOption = (
    newAnswers,
    option,
    questionAnswers,
    setNext,
    index
  ) => {
    if (
      questionAnswers.findIndex((e) => {
        return e === option;
      }) === -1
    ) {
      newAnswers[index] = option;
      newAnswers[props.question.options.length - 1] = undefined;
      setNext(true);
    } else {
      newAnswers[index] = undefined;
    }
    questionAnswers.map((ans, idx) => {
      if (idx !== props.question.options.length - 1 && idx !== index) {
        newAnswers[idx] = ans;
      }
    });
  };

  const isAnswersArrayEmpty = (newAnswers, newAnswersUpdated) => {
    let undefinedElements = 0;
    newAnswers.map((e) => {
      if (e === undefined) {
        undefinedElements++;
      } else {
        newAnswersUpdated.push(e);
      }
    });
    if (undefinedElements === newAnswers.length) {
      return true;
    }
    return false;
  };

  const handleMultiSelect = (
    questionID,
    option,
    index,
    questionAnswers,
    setQuestionAnswers,
    setNext
  ) => {
    const id = user.firstTime ? questionID : props.questionIndex;
    let newAnswers = Array.apply(undefined, {
      length: props.question.options.length,
    });
    if (props.question.singleOption === option) {
      handleSingleOption(newAnswers, option, questionAnswers, setNext);
    } else {
      handleNotSingleOption(
        newAnswers,
        option,
        questionAnswers,
        setNext,
        index
      );
    }

    const newAnswersUpdated = new Array();
    if (isAnswersArrayEmpty(newAnswers, newAnswersUpdated)) {
      setNext(false);
    }
    setQuestionAnswers(newAnswers);
    const questionAnswer = [
      {
        appearance: props.question.appearance,
        [props.question.tag]: newAnswersUpdated,
      },
    ];
    const temp = AnswersOfAllQuestions;
    if (AnswersOfAllQuestions.length - 1 === id) {
      temp.pop();
    }
    const finalAnswers = temp.concat(questionAnswer);
    setAnswers(finalAnswers);
  };

  const handleNext = (next, changeNext, someoneToHandle, value) => {
    someoneToHandle(value);

    if (next) {
      if (props.size > props.questionIndex + 1) {
        props.setQuestionIndex(props.questionIndex + 1);
      } else {
        props.setAnswers(AnswersOfAllQuestions);
        props.setLastQuestion(true);
      }
    }
    changeNext(false);
  };

  const handleAudio = () => {
    new Audio(props.question.audio).play();
  };

  const handleInput = (input, questionID, setNext) => {
    const id = user.firstTime ? questionID : props.questionIndex;
    if (input !== "") {
      setNext(true);
    } else {
      setNext(false);
    }
    const questionAnswer = [
      {
        appearance: props.question.appearance,
        [props.question.tag]: input,
      },
    ];
    const temp = AnswersOfAllQuestions;
    if (AnswersOfAllQuestions.length - 1 === id) {
      temp.pop();
    }
    const newAnswers = temp.concat(questionAnswer);
    setAnswers(newAnswers);
  };

  const chooseQuestionType = () => {
    switch (props.question?.type) {
      case "firstPrize":
        return (
          <FirstPrizeInfoPage
            direction={props.direction}
            pageType={props.question.pageType}
            handleNext={handleNext}
            prizeUpdateStatus={true}
          />
        );
      case "secondPrize":
        return (
          <SecondPrizeInfoPage
            direction={props.direction}
            pageType={props.question.pageType}
            handleNext={handleNext}
            prizeUpdateStatus={true}
          />
        );
      case "yes-or-no":
        return (
          <YesOrNoQuestionItem
            direction={props.direction}
            info={props.question}
            handleYesOrNo={handleYesOrNo}
            handleSelect={handleSelect}
            handleNext={handleNext}
            handleAudio={handleAudio}
            firstTime={user.firstTime}
          />
        );
      case "multi-select":
        return (
          <MultiSelectQuestionItem
            direction={props.direction}
            info={props.question}
            handleMultiSelect={handleMultiSelect}
            handleNext={handleNext}
            handleAudio={handleAudio}
            firstTime={user.firstTime}
          />
        );
      case "multi-select-picture":
        return (
          <MultiPictureSelectQuestionItem
            direction={props.direction}
            info={props.question}
            handleMultiSelect={handleMultiSelect}
            handleNext={handleNext}
            handleAudio={handleAudio}
            firstTime={user.firstTime}
          />
        );
      case "input":
        return (
          <InputQuestionItem
            direction={props.direction}
            info={props.question}
            handleInput={handleInput}
            handleNext={handleNext}
            handleAudio={handleAudio}
            firstTime={user.firstTime}
          />
        );
      case "gender":
        return (
          <GenderQuestionItem
            direction={props.direction}
            info={props.question}
            handleGenderQuestion={handleGenderQuestion}
            handleSelect={handleSelect}
            handleNext={handleNext}
            handleAudio={handleAudio}
            firstTime={user.firstTime}
          />
        );
      case "generic-question":
        return (
          <GenericQuestionItem
            direction={props.direction}
            info={props.question}
            handleGenericQuestion={handleGenericQuestion}
            handleNext={handleNext}
            handleAudio={handleAudio}
            firstTime={user.firstTime}
          />
        );
      default:
        return 0;
    }
  };

  return <div className="horizantalAlign">{chooseQuestionType()}</div>;
};
